import { useMemo } from 'react';
import { makeLogger } from '@/utils/makeLogger';
import { AppUserQuery } from '@/utils/narrow-utils';
import {
  CustomerDashboardQuery,
  JobListDetailsFragment,
} from 'src/__generated__/graphql';
import { JobStatus } from 'src/__generated__/graphql';
import GraphQLAlert, { AlertProps } from '@/components/ui/Alert';

const log = makeLogger('pages/index');

interface AlertsProps {
  customerData?: AppUserQuery<CustomerDashboardQuery, 'Customer'> | null;
  customerDataLoading: boolean;
  jobList: JobListDetailsFragment[];
  pastJobList: JobListDetailsFragment[];
  cancelledJobList: JobListDetailsFragment[];
  prepaidCanBookWithoutCC?: boolean | null;
}

const Alerts = ({
  customerData,
  customerDataLoading,
  jobList,
  pastJobList,
  cancelledJobList,
  prepaidCanBookWithoutCC,
}: AlertsProps) => {
  // No saved card (and not loading) and has upcoming jobs
  const showAddCardAlert = useMemo(() => {
    if (customerDataLoading) {
      log('[showAddCardAlert]', false, 'loading');
      return false;
    }

    const hasUpcoming = !!jobList.find((j) => {
      return j.status === JobStatus.Submitted || j.status === JobStatus.Claimed;
    });

    if (
      hasUpcoming &&
      customerData?.me?.savedPaymentMethod?.walletType === 'apple_pay'
    ) {
      log('[showAddCardAlert]', true, 'apple pay');
      return true;
    }

    if (hasUpcoming && prepaidCanBookWithoutCC) {
      log('[showAddCardAlert]', true, 'prepaid card');
      return true;
    }

    if (!customerData?.me?.savedPaymentMethod?.last4 && hasUpcoming) {
      log('[showAddCardAlert]', true, 'no card, upcoming job');
      return true;
    } else {
      log('[showAddCardAlert]', false, 'saved card || no upcoming');
      return false;
    }
  }, [
    customerData?.me?.savedPaymentMethod?.last4,
    customerData?.me?.savedPaymentMethod?.walletType,
    jobList,
    customerDataLoading,
    prepaidCanBookWithoutCC,
  ]);

  const unusedVoucherHours = useMemo(
    () =>
      (customerData?.me?.vouchers || []).reduce(
        (acc, v) => acc + (v?.hours || 0),
        0,
      ),
    [customerData?.me?.vouchers],
  );

  const newAlerts = useMemo(() => {
    let alerts: Array<AlertProps> = [];

    if (showAddCardAlert) {
      alerts.push({
        data: { __typename: 'NoCardAlert' },
      });
    }

    // iterate over the 3 jobs lists and get the alers from their legacyAlerts
    // and add to the list of alerts
    [...jobList, ...pastJobList, ...cancelledJobList].forEach((job) => {
      if (job.legacyAlerts) {
        job.legacyAlerts.forEach((alert) => {
          if (alert)
            alerts.push({
              data: alert,
              jobId: job.rawId,
              cleanerFirstName: job.cleaner?.firstName,
            });
        });
      }
    });

    // add customer alerts
    if (customerData?.me?.legacyAlerts) {
      customerData.me.legacyAlerts.forEach((alert) => {
        if (alert) alerts.push({ data: alert });
      });
    }

    // if the customer has unused voucher hours, add that to the CreditsAvailableAlert alert
    if (unusedVoucherHours > 0) {
      let creditsAvailableAlert = alerts.find(
        (alert) => alert.data.__typename === 'CreditsAvailableAlert',
      );
      if (!creditsAvailableAlert) {
        creditsAvailableAlert = {
          data: { __typename: 'CreditsAvailableAlert', creditsAvailable: 0 },
          unusedVoucherHours: unusedVoucherHours,
        };
        alerts.push(creditsAvailableAlert);
      } else {
        alerts = alerts.map((alert) =>
          alert.data.__typename === 'CreditsAvailableAlert'
            ? { ...alert, unusedVoucherHours }
            : alert,
        );
      }
    }
    return alerts;
  }, [
    showAddCardAlert,
    jobList,
    pastJobList,
    cancelledJobList,
    customerData?.me?.legacyAlerts,
    unusedVoucherHours,
  ]);

  return (
    <>
      {newAlerts.length > 0 ? (
        <div className="mb-4 sm:mx-1 md:mx-2 lg:mx-0">
          {newAlerts.map((alert, idx) => {
            return <GraphQLAlert key={idx} {...alert} />;
          })}
        </div>
      ) : null}
    </>
  );
};

export default Alerts;
